import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Serenity Shores Resort
			</title>
			<meta name={"description"} content={"Відкрийте для себе спокій біля моря"} />
			<meta property={"og:title"} content={"Головна | Serenity Shores Resort"} />
			<meta property={"og:description"} content={"Відкрийте для себе спокій біля моря"} />
			<meta property={"og:image"} content={"https://flixengold.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://flixengold.com/img/4793624.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://flixengold.com/img/4793624.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://flixengold.com/img/4793624.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://flixengold.com/img/4793624.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://flixengold.com/img/4793624.png"} />
			<meta name={"msapplication-TileImage"} content={"https://flixengold.com/img/4793624.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://flixengold.com/img/1.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Serenity Shores Resort
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Пориньте в заспокійливі обійми океану на курорті Serenity Shores. Наш відокремлений курорт на березі моря пропонує безтурботний притулок, де ніжні звуки хвиль створюють ідеальний фон для релаксації та омолодження.

						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
							padding="0 0 0 0"
							background="0"
							color="--primary"
						>
							Зв'яжіться з нами
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Прибережний притулок
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
						Розташований на незайманій ділянці піщаного пляжу, наш курорт є гармонійним поєднанням розкоші та природної краси. Відчуйте максимальний комфорт у наших елегантно обладнаних номерах, насолодіться першокласними спа-послугами або скуштуйте найсвіжіші страви місцевої кухні в нашому пляжному ресторані. У Serenity Shores кожна деталь створена для створення незабутнього відпочинку.
					</Text>
					<Image
						src="https://flixengold.com/img/2.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Чому варто обрати Serenity Shores?
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Морський спокій: Прокидайтеся під захоплюючі краєвиди океану та тихий шум хвиль, що набігають.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Відпочинок для гурманів: Наш ресторан на території готелю пропонує кулінарну подорож зі стравами, приготованими з місцевих продуктів.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Спа та оздоровлення: Побалуйте себе омолоджуючими процедурами в нашому спа-центрі з повним спектром послуг.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Ексклюзивні враження: Від приватних обідів на пляжі до йоги на світанку - ми створюємо моменти, які надихають.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});